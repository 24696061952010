import { Link } from "react-router-dom"
import { NavLinkMaker } from "../core/core";

function PcNav(){
    const NAV_TOP_PC = [{nameJp: "トップ", nameEn: "", path: "/home", blank: false, img: ""},
                        {nameJp: "出勤情報", nameEn: "", path: "/schedule", blank: false, img: ""},
                        {nameJp: "新妻",     nameEn: "", path: "/newface", blank: false, img: ""},
                        {nameJp: "妻一覧",     nameEn: "", path: "/allcasts", blank: false, img: ""},
                        {nameJp: "妻日記",     nameEn: "", path: "https://www.cityheaven.net/tochigi/A0901/A090101/tulipgirls/diarylist/?of=y", blank: true, img: ""},
                        {nameJp: "口コミ",     nameEn: "", path: "https://www.cityheaven.net/tochigi/A0901/A090101/tulipgirls/reviews/?of=y", blank: true, img: ""},
                        {nameJp: "予約はこちら",     nameEn: "", path: "https://www.cityheaven.net/tochigi/A0901/A090101/tulipgirls/A6ShopReservation/?of=y", blank: true, img: ""},
                        {nameJp: "料金表",     nameEn: "", path: "https://www.cityheaven.net/tochigi/A0901/A090101/tulipgirls/system/?of=y", blank: true, img: ""},
                        {nameJp: "アクセス",     nameEn: "", path: "/access", blank: false, img: ""},
                        {nameJp: "男性求人",     nameEn: "", path: "https://mens-qzin.jp/tochigi/area_09001/detail/utsunomiyatulipgirls/", blank: true, img: ""},
                        {nameJp: "女性求人",     nameEn: "", path: "https://kitakanto.qzin.jp/tulipg/?v=official", blank: true, img: "", tagForced: true, requireTag: "vanilla"},
    ];

    return(
        <div id="pc-header" className="pc">
            <nav className="wrapper-pcnav">
                <ul className="pcnav txt-center">
                    {NAV_TOP_PC.map((el)=>{
                        return(
                            <li key={el.nameEn}>
                                <NavLinkMaker data={el}>
                                    {el.nameJp}
                                </NavLinkMaker>
                            </li>
                        )
                    })}
                </ul>
            </nav>
        </div>
    );

}

export default PcNav;